import Footer from "../components/Footer";
import Header from "../components/Header";
import { useGetData } from "../hooks/useGetData";

const Blog = () => {
  const { data, loading } = useGetData("blogs");

  return (
    <div className="dark">
      <Header />
      <section
        id="home"
        className="bg-white dark:bg-gray-900 pt-0 dark min-h-screen flex justify-center"
      >
        {loading ? (
          <div class="w-12 h-12 rounded-full animate-spin border-2 border-solid border-blue-500 border-t-transparent"></div>
        ) : (
          <div class="dark:bg-gray-900 py-24 text-white sm:py-32 ">
            <div class="mx-auto max-w-7xl px-6 lg:px-8">
              <div class="mx-auto max-w-2xl lg:mx-0">
                <h2 class="text-4xl font-semibold tracking-tight text-pretty text-white sm:text-5xl">
                  From the blog
                </h2>
                <p class="mt-2 text-lg/8 text-white">
                  Learn how to grow your business with our expert advice.
                </p>
              </div>

              <div class="mx-auto mt-10 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 border-t border-gray-200 pt-10 sm:mt-16 sm:pt-16 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                {data?.map((dataItem) => (
                  <article class="flex max-w-xl flex-col items-start justify-between">
                    <div class="flex items-center gap-x-4 text-xs">
                      <time
                        datetime={new Date(dataItem?.updated).toLocaleString()}
                        class="text-gray-500"
                      >
                        {new Date(dataItem?.updated).toLocaleString()}
                      </time>
                      {dataItem?.labels.map((labels) => (
                        <p class="relative z-10 rounded-full bg-[#e62552] px-3 py-1.5 font-medium text-white hover:bg-black">
                          {labels}
                        </p>
                      ))}
                    </div>
                    <div class="group relative">
                      <h3 class="mt-3 text-lg/6 font-semibold text-#9334e9 group-hover:text-white">
                        <a href={`/blogs/${dataItem?.id}`}>
                          <span class="absolute inset-0"></span>
                          {dataItem?.title}
                        </a>
                      </h3>
                      <a
                        href={`/blogs/${dataItem?.blog?.id}`}
                        class="text-sm/6 font-semibold text-[#9334e8]"
                      >
                        Read More <span aria-hidden="true">→</span>
                      </a>
                    </div>
                    {/* <div class="relative mt-8 flex items-center gap-x-4">
                    <img
                 x     src={dataItem?.author?.image?.url}
                      alt=""
                      class="size-10 rounded-full bg-gray-50"
                    />
                    <div class="text-sm/6">
                      <p class="font-semibold text-white">
                        <a href="#">
                          <span class="absolute inset-0"></span>
                          {dataItem?.author?.displayName}
                        </a>
                      </p>
                      {/* <p class="text-white">Co-Founder / CTO</p> */}
                    {/* </div>
                  </div>  */}
                  </article>
                ))}
              </div>
            </div>
          </div>
        )}
      </section>

      <Footer />
    </div>
  );
};
export default Blog;
