import { Card, Divider, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  LabelList,
  Label,
} from "recharts";

import "../index.css";
import { BorderColor } from "@mui/icons-material";
const ModelNPSBarChart = ({ data }) => {
  const theme = useTheme();
  const [ratingArr, setRatingArr] = useState(null);

  function calculateMonthlyNPS(responses) {
    const modelNPS = {};

    for (const response of responses) {
      const model = response.Model;
      if (response.dealer_feedback) {
        if (!modelNPS[model]) {
          modelNPS[model] = {
            promoters: 0,
            detractors: 0,
            total: 0,
          };
        }

        if (response.dealer_feedback === "excellent") {
          modelNPS[model].promoters++;
        } else if (response.dealer_feedback === "poor") {
          modelNPS[model].detractors++;
        }
        modelNPS[model].total++;
      }
    }
    let modelWiseNPSArr = [];
    for (const model in modelNPS) {
      const { promoters, detractors, total } = modelNPS[model];
      let nps = ((promoters - detractors) / total) * 100;
      nps = nps.toFixed(0);
      modelWiseNPSArr.push({ model, nps });
    }
    return modelWiseNPSArr;
  }

  useEffect(
    () => {
      if (data) {
        setRatingArr(calculateMonthlyNPS(data));
      }
    },
    [],
    data
  );

  const CustomTick = (props) => {
    const { x, y, payload } = props;
    const words = payload.value.split(" ");
    const lines = [];
    let line = "";

    words.forEach((word) => {
      if ((line + word).length > 10) {
        lines.push(line);
        line = word;
      } else {
        line = line ? `${line} ${word}` : word;
      }
    });

    lines.push(line);

    return (
      <g transform={`translate(${x},${y + 10})`}>
        {lines.map((line, index) => (
          <text
            key={index}
            x={0}
            y={index * 12}
            textAnchor="middle"
            fill="white"
            fontSize={12}
            background="white"
          >
            {line}
          </text>
        ))}
      </g>
    );
  };

  return (
    <Card
      className="custom-card"
      variant="outlined"
      sx={{
        boxShadow: 3,
        width: "100%",
        height: "300px",
        p: "15px",
        background: "#1d2637",
        color: "white",
        fill: "white",
        borderRadius: "12px",
      }}
    >
      <Typography variant="overline" gutterBottom>
        Model wise NPS
      </Typography>
      <Divider />
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          data={ratingArr}
          margin={{
            top: 20,
            right: 30,
            left: 0,
            bottom: 50,
          }}
          width={150}
          height={40}
          style={{ fill: "white" }}
        >
          <XAxis dataKey="model" tick={<CustomTick />}>
            <Label
              value="Model"
              offset={-25}
              position="insideBottom"
              style={{ fill: "white", color: "white" }}
            />
          </XAxis>
          <YAxis>
            <Label
              value="NPS Score"
              angle={-90}
              position="insideLeft"
              style={{ textAnchor: "middle", fill: "white", color: "white" }}
            />
          </YAxis>
          <Tooltip />
          <Bar dataKey="nps" fill="#40f9d0">
            <LabelList
              dataKey="nps"
              position="top"
              style={{
                ...theme.typography.caption,
                fill: "white",
              }}
            />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </Card>
  );
};

export default ModelNPSBarChart;
