import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import FeedbackChart from "../components/FeedbackChart";
import axios from "axios";
import { API_DOMAIN } from "../utils/contants";
import { useAuth } from "../components/AuthProvider";
import { useNavigate } from "react-router-dom";

function Dashboard() {
  const [npsDetails, setNpsDeatails] = useState([]);
  const [loading, setLoading] = useState(true);
  const { logout } = useAuth();
  const navigate = useNavigate();

  const fetchNPSDetails = async () => {
    try {
      const response = await axios.get(`${API_DOMAIN}/nps-details`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      setNpsDeatails(response.data);
    } catch (err) {
      console.error(err);
      logout(navigate("/"));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchNPSDetails();
  }, []);

  return (
    <div className="dark">
      <Header />
      {loading ? (
        <p>Loading...</p>
      ) : (
        <div>
          <FeedbackChart npsData={npsDetails} />
        </div>
      )}
    </div>
  );
}

export default Dashboard;
