import { Box, Grid2 } from "@mui/material";
import React, { useEffect, useState } from "react";
import FeedbackPieChart from "./FeedbackPieChart";
import MonthwiseFeedbackChart from "./MonthwiseFeedbackChart";
import NPSScroreWidget from "./NPSScroreWidget";
import UserDataTable from "./UserDataTable";
import ConcernCategoryBarChart from "./ConcernCategoryBarChart";
import SubConcernCategoryBarChart from "./SubConcernCategoryBarChart ";
import ModelNPSBarChart from "./ModelNPSBarChart";
import TLNPSBarChart from "./TLNPSBarChart";
import DSENPSBarChart from "./DSENPSBarChart";

const FeedbackChart = ({ npsData }) => {
  const [npsScore, setNpsScore] = useState(0);
  const [concernData, setConcernData] = useState(null);
  const [subConcernData, setSubConcernData] = useState(null);

  const calculateNPSScore = (npsData) => {
    const total = npsData.filter((item) => item.dealer_feedback).length;
    const excellentCount = npsData.filter(
      (item) => item.dealer_feedback === "excellent"
    ).length;
    const poorCount = npsData.filter(
      (item) => item.dealer_feedback === "poor"
    ).length;
    const percentage = (excellentCount - poorCount) / total;
    setNpsScore(percentage.toFixed(1) * 100);
  };

  const countLowRatingReason = (npsData) => {
    const reasonCount = npsData.reduce((acc, obj) => {
      let reason = obj.low_rating_reason;
      if (reason) {
        reason = reason
          .replace(/_/g, " ")
          .replace(/\b\w/g, (char) => char.toUpperCase());
        acc[reason] = (acc[reason] || 0) + 1;
      }
      return acc;
    }, {});

    const resultArray = Object.entries(reasonCount).map(([reason, value]) => ({
      reason,
      value,
    }));
    setConcernData(resultArray);
  };

  const countSubConcern = (npsData) => {
    const reasonCount = npsData.reduce((acc, obj) => {
      let reason = obj.low_rating_sub_reason;
      if (reason) {
        reason = reason
          .replace(/_/g, " ")
          .replace(/\b\w/g, (char) => char.toUpperCase());
        acc[reason] = (acc[reason] || 0) + 1;
      }
      return acc;
    }, {});

    const resultArray = Object.entries(reasonCount).map(([reason, value]) => ({
      reason,
      value,
    }));
    setSubConcernData(resultArray);
  };

  useEffect(() => {
    calculateNPSScore(npsData);
    countLowRatingReason(npsData);
    countSubConcern(npsData);
  }, []);

  return (
    <div className="bg-white dark:bg-gray-900 py-20">
      <Box
        sx={{
          flexGrow: 1,
          fontFamily: "Roboto, Arial, sans-serif", // Replace with your font.
        }}
      >
        <Grid2 container spacing={6} sx={{ m: "20px", marginRight: "50px" }}>
          <Grid2 size={{ xs: 12, md: 3 }} md={{ color: "white" }}>
            {npsScore && <NPSScroreWidget score={npsScore} />}
          </Grid2>
          <Grid2 size={{ xs: 12, md: 3 }}>
            {npsData && <FeedbackPieChart data={npsData} />}
          </Grid2>
          <Grid2 size={{ xs: 12, md: 6 }}>
            {npsData && <ModelNPSBarChart data={npsData} />}
          </Grid2>
          <Grid2 size={{ xs: 12, md: 6 }}>
            {npsData && <TLNPSBarChart data={npsData} />}
          </Grid2>
          <Grid2 size={{ xs: 12, md: 6 }}>
            {npsData && <DSENPSBarChart data={npsData} />}
          </Grid2>
          <Grid2 size={{ xs: 12, md: 4 }}>
            {npsData && <MonthwiseFeedbackChart data={npsData} />}
          </Grid2>
          <Grid2 size={{ xs: 12, md: 4 }}>
            <ConcernCategoryBarChart concernData={concernData} />
          </Grid2>
          <Grid2 size={{ xs: 12, md: 4 }}>
            <SubConcernCategoryBarChart subConcernData={subConcernData} />
          </Grid2>
          <Grid2 size={12}>{npsData && <UserDataTable data={npsData} />}</Grid2>
        </Grid2>
      </Box>
    </div>
  );
};

export default FeedbackChart;
