import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import ProtectedRoute from "./components/ProtectedRoute";
import { useAuth } from "./components/AuthProvider";
import AddCustomers from "./pages/AddCustomers";
import Home from "./pages/Home";
import Blog from "./pages/Blog";
import { RecoilRoot } from "recoil";
import BlogDetails from "./pages/BlogDetails";

function App() {
  const { isAuthenticated } = useAuth();

  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Home />} />

        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/add-customer"
          element={
            <ProtectedRoute>
              <AddCustomers />
            </ProtectedRoute>
          }
        />
        <Route
          path="/blogs/:id"
          element={
            <RecoilRoot>
              <BlogDetails />
            </RecoilRoot>
          }
        />
        <Route
          path="/blogs"
          element={
            <RecoilRoot>
              <Blog />
            </RecoilRoot>
          }
        />

        <Route
          path="*"
          element={
            isAuthenticated ? <Navigate to="/dashboard" /> : <Navigate to="/" />
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
