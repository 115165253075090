import React, { useState } from "react";
import { Button, Typography, Box } from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { API_DOMAIN } from "../utils/contants";
import * as XLSX from "xlsx";

const FileUpload = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [severity, setSeverity] = useState("success");
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const navigate = useNavigate();

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
    }
  };

  const handleUpload = async () => {
    if (!selectedFile) return;

    const reader = new FileReader();
    reader.onload = async (event) => {
      try {
        const arrayBuffer = event.target.result;

        const workbook = XLSX.read(arrayBuffer, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const jsonData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);

        await axios.post(
          `${API_DOMAIN}/upload-report`,
          {
            data: jsonData,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        setAlertMessage("File uploaded successfully!");
        setSeverity("success");
      } catch (error) {
        setAlertMessage("Error uploading file.");
        setSeverity("error");
      } finally {
        setAlertOpen(true);
        setSelectedFile(null);
      }
    };

    reader.readAsArrayBuffer(selectedFile);
  };

  const handleCancel = () => {
    navigate("/dashboard");
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: 2,
        border: "1px dashed #ccc",
        borderRadius: 2,
        width: "300px",
        margin: "0 auto",
      }}
    >
      <Typography variant="h6">File Upload</Typography>
      <input
        style={{ display: "none" }}
        id="file-upload"
        type="file"
        onChange={handleFileChange}
        accept=".xls,.xlsx"
      />
      <label htmlFor="file-upload">
        <Button variant="contained" component="span" color="primary">
          Choose File
        </Button>
      </label>
      {selectedFile && (
        <Typography variant="body1" sx={{ marginTop: 2 }}>
          Selected File: {selectedFile.name}
        </Typography>
      )}
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Button
          variant="outlined"
          color="error"
          onClick={handleCancel}
          sx={{ margin: 2 }}
        >
          Cancel
        </Button>
        <Button
          variant="outlined"
          color="white"
          onClick={handleUpload}
          disabled={!selectedFile}
          sx={{ margin: 2, color: "white" }}
        >
          Upload
        </Button>
      </Box>
      {alertOpen && (
        <Typography variant="body1" sx={{ marginTop: 2 }} color={{ severity }}>
          {alertMessage}
        </Typography>
      )}
    </Box>
  );
};

export default FileUpload;
