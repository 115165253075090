import React from "react";
import { Box, Button, Typography } from "@mui/material";
import Header from "../components/Header";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import FileUpload from "../components/FileUpload";
import { Height } from "@mui/icons-material";

const AddCustomers = () => {
  const navigate = useNavigate();

  return (
    <div
      className="dark"
      style={{ backgroundColor: "#101827", minHeight: "100vh" }}
    >
      <Header />
      <div className="pt-12 dark">
        {" "}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            maxWidth: "60%",
            margin: "20px auto",
            padding: 3,
            boxShadow: 3,
            borderRadius: 2,
            bgcolor: "#1d2637",
            color: "white",
          }}
        >
          <Button
            variant="text"
            color="primary"
            onClick={() => navigate("/dashboard")}
            startIcon={<ArrowBackIcon />}
            sx={{ marginBottom: 2, alignSelf: "flex-start" }}
          >
            Back
          </Button>

          <Typography variant="h5" gutterBottom>
            Add Customers
          </Typography>
          <FileUpload />
        </Box>
      </div>
    </div>
  );
};

export default AddCustomers;
