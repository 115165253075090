import { useEffect } from "react";
import { useGetData } from "../hooks/useGetData";
import { blogData } from "../recoil/atom/blogData";
import { useRecoilState, useSetRecoilState } from "recoil";
import Header from "../components/Header";
import Footer from "../components/Footer";

const BlogDetails = () => {
  var blogId = window.location.pathname.split("/blogs/")[1];

  const { data, loading } = useGetData(`blogs/${blogId}`);

  return (
    <div className="dark">
      <Header />
      {/* Other sections */}

      <section
        id="home"
        className="bg-white dark:bg-gray-900 pt-0 dark min-h-svh flex justify-center"
      >
        {loading ? (
          <div
            class="w-12 h-12 rounded-full animate-spin border-2 border-solid border-blue-500 border-t-transparent"
          ></div>
        ) : (
          <div class="dark:bg-gray-900 py-1 text-white sm:py-1 ">
            <div class="mx-auto max-w-7xl px-6 lg:px-8">
              <div class="mx-auto mt-10     gap-y-16 pt-10 sm:mt-16 sm:pt-16 lg:mx-0 lg:max-w-none">
                <article class="flex max-100 flex-col items-start justify-between">
                  <div class="flex items-center gap-x-4 text-xs">
                    <time
                      datetime={new Date(data?.updated).toLocaleString()}
                      class="text-gray-500"
                    >
                      {new Date(data?.updated).toLocaleString()}
                    </time>
                    {data?.labels?.map((labels) => (
                      <p class="relative z-10 rounded-full bg-[#e62552] px-3 py-1.5 font-medium text-white hover:bg-black">
                        {labels}
                      </p>
                    ))}
                  </div>
                  <div class="group relative">
                    <h1 class="mt-3 text-3xl text-#9334e9 group-hover:text-white">
                      {data?.title}
                    </h1>
                    <div
                      dangerouslySetInnerHTML={{ __html: data?.content }}
                      style={{
                        padding: "10px",
                        //   fontFamily: "Arial, sans-serif",
                      }}
                    />
                  </div>
                </article>
              </div>
            </div>
          </div>
        )}
      </section>

      <Footer />
    </div>
  );
};
export default BlogDetails;
