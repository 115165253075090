import React from "react";
import HeroImage from "../assets/images/bizecho-homepage.png";
import DashboardBizEcho from "../assets/images/dashboard-stats-bizecho.png";
import Header from "../components/Header";
import Footer from "../components/Footer";

const Home = () => {
  return (
    <div className="dark">
      <Header />
      {/* Other sections */}
      <section id="home" className="bg-white dark:bg-gray-900 py-20">
        <div className="grid max-w-screen-xl px-4 pt-20 pb-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12 lg:pt-28">
          <div className="mr-auto place-self-center lg:col-span-7">
            <h1 className="max-w-2xl mb-4 text-4xl font-extrabold leading-none tracking-tight md:text-5xl xl:text-6xl dark:text-white">
              Empower Your Business with Actionable Insights
            </h1>
            <p className="max-w-2xl mb-6 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">
              Seamlessly gather customer feedback via WhatsApp, analyze data,
              and unlock growth opportunities with customized dashboards.
            </p>
            <button className="text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 sm:mr-2 lg:mr-0 dark:bg-purple-600 dark:hover:bg-purple-700 focus:outline-none dark:focus:ring-purple-800">
              Explore Our Services
            </button>
          </div>
          <div className="hidden lg:mt-0 lg:col-span-5 lg:flex">
            <img src={HeroImage} alt="Hero" />
          </div>
        </div>
      </section>

      {/* <!-- Start block --> */}
      <section id="about" class="bg-gray-50 dark:bg-gray-800">
        <div class="max-w-screen-xl px-4 py-8 mx-auto space-y-12 lg:space-y-20 lg:py-10 lg:px-6">
          <div class="items-center gap-8 lg:grid lg:grid-cols-2 xl:gap-16">
            <img
              class="hidden w-full mb-4 rounded-lg lg:mb-0 lg:flex"
              src={DashboardBizEcho}
              alt="feature image 2"
            />
            <div class="text-gray-500 sm:text-lg dark:text-gray-400">
              <h2 class="mb-4 text-3xl font-extrabold tracking-tight text-gray-900 dark:text-white">
                About Us
              </h2>
              <p class="mb-8 font-light lg:text-xl">
                At Bizecho, we specialize in empowering businesses by turning
                customer feedback into strategic growth opportunities.
              </p>
              <ul
                role="list"
                class="pt-8 space-y-5 border-t border-gray-200 my-7 dark:border-gray-700"
              >
                <li class="flex space-x-3">
                  <svg
                    class="flex-shrink-0 w-5 h-5 text-purple-500 dark:text-purple-400"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">
                    Our innovative approach ensures every voice is heard, and
                    every insight is actionable.
                  </span>
                </li>

                <li class="flex space-x-3">
                  <svg
                    class="flex-shrink-0 w-5 h-5 text-purple-500 dark:text-purple-400"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">
                    From small startups to established enterprises across
                    industries like restaurants, automobiles, real estate,
                    travel, and hotels, we provide solutions tailored to your
                    unique needs.
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End block --> */}

      {/* <!-- Start block --> */}
      <section id="services" class="bg-white dark:bg-gray-900">
        <div class="items-center max-w-screen-xl px-4 py-8 mx-auto lg:grid lg:grid-cols-4 lg:gap-16 xl:gap-24 lg:py-24 lg:px-6">
          <div class="col-span-2 mb-8">
            <p class="text-lg font-medium text-purple-600 dark:text-purple-500">
              Services
            </p>
            <h2 class="mt-3 mb-4 text-3xl font-extrabold tracking-tight text-gray-900 md:text-3xl dark:text-white">
              Transforming Feedback into Actionable Growth
            </h2>
            <p class="font-light text-gray-500 sm:text-xl dark:text-gray-400">
              We offer seamless WhatsApp-based feedback collection, an intuitive
              admin dashboard with advanced analytics, and tailored solutions to
              help businesses turn customer insights into growth opportunities.
            </p>
          </div>
          <div class="col-span-2 space-y-8 md:grid md:grid-cols-2 md:gap-12 md:space-y-0">
            <div>
              <svg
                class="w-10 h-10 mb-2 text-purple-600 md:w-12 md:h-12 dark:text-purple-500"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M2 5a2 2 0 012-2h12a2 2 0 012 2v2a2 2 0 01-2 2H4a2 2 0 01-2-2V5zm14 1a1 1 0 11-2 0 1 1 0 012 0zM2 13a2 2 0 012-2h12a2 2 0 012 2v2a2 2 0 01-2 2H4a2 2 0 01-2-2v-2zm14 1a1 1 0 11-2 0 1 1 0 012 0z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <h3 class="mb-2 text-2xl font-bold dark:text-white">
                WhatsApp Feedback Collection
              </h3>
              <p class="font-light text-gray-500 dark:text-gray-400">
                Engage your customers effortlessly via WhatsApp to collect
                real-time feedback.
              </p>
            </div>
            <div>
              <svg
                class="w-10 h-10 mb-2 text-purple-600 md:w-12 md:h-12 dark:text-purple-500"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z"></path>
              </svg>
              <h3 class="mb-2 text-2xl font-bold dark:text-white">
                Data Analysis & Dashboards
              </h3>
              <p class="font-light text-gray-500 dark:text-gray-400">
                Analyze customer feedback with intuitive dashboards designed to
                provide actionable insights.
              </p>
            </div>
            <div>
              <svg
                class="w-10 h-10 mb-2 text-purple-600 md:w-12 md:h-12 dark:text-purple-500"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zM4.332 8.027a6.012 6.012 0 011.912-2.706C6.512 5.73 6.974 6 7.5 6A1.5 1.5 0 019 7.5V8a2 2 0 004 0 2 2 0 011.523-1.943A5.977 5.977 0 0116 10c0 .34-.028.675-.083 1H15a2 2 0 00-2 2v2.197A5.973 5.973 0 0110 16v-2a2 2 0 00-2-2 2 2 0 01-2-2 2 2 0 00-1.668-1.973z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <h3 class="mb-2 text-2xl font-bold dark:text-white">
                Industry-Specific Solutions
              </h3>
              <p class="font-light text-gray-500 dark:text-gray-400">
                Tailored insights for industries like restaurants, travel, real
                estate, and more.
              </p>
            </div>
            <div>
              <svg
                class="w-10 h-10 mb-2 text-purple-600 md:w-12 md:h-12 dark:text-purple-500"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M3 1a1 1 0 000 2h1.22l.305 1.222a.997.997 0 00.01.042l1.358 5.43-.893.892C3.74 11.846 4.632 14 6.414 14H15a1 1 0 000-2H6.414l1-1H14a1 1 0 00.894-.553l3-6A1 1 0 0017 3H6.28l-.31-1.243A1 1 0 005 1H3zM16 16.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM6.5 18a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"></path>
              </svg>
              <h3 class="mb-2 text-2xl font-bold dark:text-white">
                Seamless Integration
              </h3>
              <p class="font-light text-gray-500 dark:text-gray-400">
                Easily integrate feedback data into your CRM, billing, and other
                business systems for streamlined workflows.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End block --> */}

      <section id="contact" class="bg-gray-50 dark:bg-gray-800">
        <div class="max-w-screen-xl px-4 py-8 mx-auto lg:py-16 lg:px-6">
          <div class="max-w-screen-sm mx-auto text-center">
            <h2 class="mb-4 text-3xl font-extrabold leading-tight tracking-tight text-gray-900 dark:text-white">
              Contact Us
            </h2>
            <p class="mb-6 font-light text-gray-500 dark:text-gray-400 md:text-lg">
              Ready to transform your business with our services? Reach out to
              us today! We’d love to hear from you! Reach out to us via phone or
              email, and we’ll be happy to assist.{" "}
            </p>
            <div className="flex flex-between flex-col md:flex-row my-3 md:my-0 justify-center">
              <a
                href="tel:919315880593"
                class="text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 focus:outline-none dark:focus:ring-purple-800"
              >
                Call Us : +91 9315 880 593
              </a>
              <a
                href="mailto:contact@bizecho.co.in"
                class="text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 focus:outline-none dark:focus:ring-purple-800"
              >
                Email us : contact@bizecho.co.in
              </a>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Home;
